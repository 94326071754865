import { useEffect, useState, useContext } from 'react'
import { Button, Stack } from '@mui/material'
import { FilterPanelDropdowns } from 'components/FilterPanel'
import pickBy from 'lodash/pickBy'
import mapValues from 'lodash/mapValues'
import mapKeys from 'lodash/mapKeys'
import type { DataProviderContext } from 'providers/data'
import type { SelectData } from 'components/PureSelect/types'

interface ParameterFormsProps {
  initialParams?: any
  selectedParams?: any
  setSelectedParams?: any
  setReady?: any
}

export const ParameterForms = ({
  initialParams,
  selectedParams,
  setSelectedParams,
  setReady,

  // Inherited from data context
  data
}: ParameterFormsProps & DataProviderContext<SelectData>) => {
  // All filter data inc. options, set when data is received
  const [reportParams, setReportParams] = useState([])
  // variables to capture dropdowns as they're set
  const [dropdownsValues, setDropdownsValues] =
    //@ts-ignore
    useState<FilterValues>({})

  useEffect(() => {
    if (data !== undefined) {
      //@ts-ignore
      setReportParams(data)
    }
  }, [data])

  const parseParamOutputValues = (
    values: any
  ): any =>
    pickBy(
      {
        ...mapValues(
          mapKeys(values.dropdownsValues, (_, key) => `${key}`),
          //@ts-ignore
          value => value.map(({ id }) => id)
        )
      },
      value => value !== null && value.length > 0
    )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const parsedValues = parseParamOutputValues({ dropdownsValues })

    const mergedParams = {
      ...initialParams,
      // ...selectedParams,
      ...parsedValues
    }
    console.log('[PFD.tsx] SUBMIT merged params: ', mergedParams)

    setSelectedParams(mergedParams)
    setReady(false)
  }

  return (
    <form
      onSubmit={handleSubmit}
    >
      <Stack direction="row" spacing={2} sx={{ paddingBottom: '1em' }}>
        <FilterPanelDropdowns
          //@ts-ignore
          dropdownsValues={dropdownsValues}
          //@ts-ignore
          filters={reportParams}
          //@ts-ignore
          setDropdownsValues={setDropdownsValues}
        />
        <div className='gt-parameter-forms__button'>
          <Button
            fullWidth
            variant='outlined'
            type='submit'
            sx={{
              width: 200,
              height: 56,
              paddingTop: '0.5em',
              marginTop: '0.5em'
            }}
          >
            Apply Filters
          </Button>
        </div>
      </Stack>
    </form>
  )
}
