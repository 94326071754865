import { SyntheticEvent } from 'react'
import classNames from 'classnames'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MuiSelect from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import FormHelperText from '@mui/material/FormHelperText'
import { SelectData, SelectOptionData } from 'components/PureSelect/types'

import './PureSelect.css'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { castArray } from 'lodash'

type SelectedValues = SelectOptionData['id'][]

export interface PureSelectProps {
  className?: string
  style?: Object

  name: string
  options: SelectOptionData[]
  label: string
  value: SelectedValues
  formHelperText?: string | null
  multiple?: boolean
  handleChange: (selectedOptions: SelectedValues) => void
  handleClear?: (id: SelectData['id']) => void
  handleDelete?: (optionId: SelectOptionData['id']) => void
}

export const PureSelect = ({
  className,
  style,

  label,
  name,

  formHelperText,
  value,
  options,
  multiple,

  handleChange,
  handleClear,
  handleDelete
}: PureSelectProps) => (
  <div className={classNames('gt-pure-select', className)} style={style}>
    <div className='gt-pure-select__input'>
      <FormControl fullWidth>
        <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
        <MuiSelect
          labelId={`select-${name}-label`}
          id={`select-${name}`}
          className='gt-pure-select__dropdown'
          value={value?.length ? value : multiple ? [] : ''}
          name={name}
          multiple={multiple}
          onChange={event => {
            const value = event.target.value
            const parsedValue =
              typeof value === 'string'
                ? value.split(',').map(Number)
                : castArray(value)

            handleChange(parsedValue)
          }}
          {...(multiple && {
            renderValue: (selected: SelectedValues) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map(id => (
                  <Chip
                    variant='outlined'
                    key={id}
                    onMouseDown={(event: SyntheticEvent) => {
                      event.preventDefault()
                      event.stopPropagation()
                    }}
                    label={
                      options.find(option => option.id === id)?.display_name ??
                      ''
                    }
                    {...(handleDelete && {
                      onDelete: e => {
                        e.stopPropagation()
                        handleDelete(id)
                      }
                    })}
                  />
                ))}
              </Box>
            )
          })}
        >
          {options.map((option: any) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.display_name}
              </MenuItem>
            )
          })}
        </MuiSelect>
        {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
      </FormControl>
    </div>
    {!multiple && handleClear && (
      <IconButton
        onClick={() => handleClear(name)}
        sx={{ visibility: value ? 'visible' : 'hidden' }}
        className='gt-pure-select__clear'
      >
        <ClearIcon />
      </IconButton>
    )}
  </div>
)

export default PureSelect
