import PureSelect from 'components/PureSelect'
import type { FilterPanelDropdownsValues } from 'components/FilterPanel/types'
import type { DataProviderContext } from 'providers/data'
import type { SelectData, SelectOptionData } from 'components/PureSelect/types'

export interface FilterPanelDropdownsProps {
  filters: NonNullable<DataProviderContext<SelectData>['data']>
  dropdownsValues: FilterPanelDropdownsValues
  setDropdownsValues: React.Dispatch<
    React.SetStateAction<FilterPanelDropdownsValues>
  >
}

export const FilterPanelDropdowns = ({
  filters,
  dropdownsValues,
  setDropdownsValues
}: FilterPanelDropdownsProps) => {
  const handleDeleteOption = (
    optionToDelete: SelectOptionData['id'],
    selectId: SelectData['id']
  ) => {
    const updatedFormValues = { ...dropdownsValues }
    updatedFormValues[selectId] = updatedFormValues[selectId].filter(
      option => option.id !== optionToDelete
    )
    setDropdownsValues(updatedFormValues)
    console.log('handleDelete called: ', dropdownsValues)
  }

  const handleSelectOption = (
    selectedOptions: SelectOptionData['id'][],
    selectId: SelectData['id'],
    selectOptions: SelectOptionData[]
  ) => {
    const newSelectedOptions = selectedOptions.map(
      (selectedId: SelectOptionData['id']) =>
        selectOptions.find(({ id }) => id === selectedId)!
    )

    setDropdownsValues({
      ...dropdownsValues,
      [selectId]: newSelectedOptions
    })
    console.log('handleSelectOption called: ', dropdownsValues)
  }

  return (
    <>
      {filters.map(filterData => {
        return (
          <PureSelect
            key={filterData.id}
            name={filterData.id}
            label={filterData.display_name}
            options={filterData.options}
            handleChange={selectedOptions =>
              handleSelectOption(
                selectedOptions,
                filterData.id,
                filterData.options
              )
            }
            multiple={filterData.filter_type === 'multi_select'}
            handleDelete={selectOptionId =>
              handleDeleteOption(selectOptionId, filterData.id)
            }
            value={(dropdownsValues[filterData.id] || []).map(({ id }) => id)}
          />
        )
      })}
    </>
  )
}
