import { SyntheticEvent, useState } from 'react'
import classNames from 'classnames'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Button from '@mui/material/Button'
import CaretIcon from '@mui/icons-material/ChangeHistoryRounded'
import Typography from 'components/Typography'
import { DataProviderContext } from 'providers/data'
import {
  QuestionData,
  QuestionPickerDataParsed
} from 'components/QuestionPicker/types'
import { defaultQuestionPickerDataParser } from 'components/QuestionPicker/utils'

import './QuestionPicker.css'

export interface QuestionPickerProps {
  className?: string
  style?: Object

  dataParser?: (d: QuestionData[], q?: string[]) => QuestionPickerDataParsed
  handleQuestionPick: (questionInfo: QuestionData) => void
  initialCategory?: string
  questionsToOmit?: string[]
}

export const QuestionPicker = ({
  className,
  style,

  data,

  dataParser = defaultQuestionPickerDataParser,
  handleQuestionPick,
  initialCategory,
  questionsToOmit = []
}: QuestionPickerProps &
  DataProviderContext<QuestionData>): JSX.Element | null => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    initialCategory ?? null
  )

  const handleCategorySelect =
    (category: string) => (_: SyntheticEvent, newExpandedNode: boolean) => {
      setExpandedAccordion(newExpandedNode ? category : null)
    }

  if (!data) {
    return null
  }

  const transformedData = dataParser(data, questionsToOmit)

  return (
    <Box className={classNames('gt-q-picker', className)} style={style}>
      {transformedData.map(({ category, questions }, i) => (
        <Accordion
          key={category}
          disableGutters
          expanded={expandedAccordion === category}
          onChange={handleCategorySelect(category)}
          elevation={0}
          square
          className='gt-q-picker__category'
        >
          <AccordionSummary
            aria-controls={`category-content-${i}`}
            id={`category-header-${i}`}
            expandIcon={<CaretIcon style={{ color: '#393945' }} />}
          >
            <Typography className='gt-q-picker__category-text'>
              {category}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className='gt-q-picker__questions-list'>
            {questions.map(({ questionText, variableKey }) => (
              <Button
                key={variableKey}
                className='gt-q-picker__question'
                onClick={() =>
                  handleQuestionPick({
                    category,
                    variable_key: variableKey,
                    question_text: questionText
                  })
                }
              >
                {questionText}
              </Button>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

export default QuestionPicker
