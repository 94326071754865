import castArray from 'lodash/castArray'
import uniq from 'lodash/uniq'
import { parseISO, isValid } from 'date-fns'
import type { SelectData, SelectOptionData } from 'components/PureSelect/types'
import type {
  QueryParams,
  ParsedQueryParams
} from 'components/FilterPanel/types'

const FILTER_VALUE_LIMIT = 100

const isSelectOption = (
  option: SelectOptionData | undefined
): option is SelectOptionData => typeof option !== 'undefined'

export const parseInputValues = (
  filterValuesFromQuery: QueryParams | undefined,
  availableFilters: SelectData[]
) => {
  if (!filterValuesFromQuery) {
    return {}
  }

  return Object.entries(filterValuesFromQuery).reduce<ParsedQueryParams>(
    (acc, [key, value]) => {
      const lowerKey = key.toLowerCase()

      if (lowerKey.startsWith('dropdown_')) {
        const dropdownId = lowerKey.slice(9)
        const existingFilter = availableFilters.find(
          ({ id }) => id.toLowerCase() === dropdownId
        )
        if (!existingFilter || !Array.isArray(value)) {
          return acc
        }

        const values = uniq(castArray(value))
          .filter((val: string) => !Number.isNaN(parseInt(val, 10)))
          .map((val: string) => parseInt(val, 10))

        if (values.length === 0) {
          return acc
        }

        return {
          ...acc,
          dropdownsValues: {
            ...acc.dropdownsValues,
            [dropdownId]: values
              .slice(0, FILTER_VALUE_LIMIT)
              .map(id =>
                existingFilter.options.find(
                  ({ id: optionId }) => id === optionId
                )
              )
              .filter(isSelectOption)
          }
        }
      }

      if (
        ['startdate', 'enddate'].includes(lowerKey) &&
        typeof value === 'string' &&
        value.length > 0
      ) {
        const date = parseISO(value)
        if (isValid(date)) {
          if (lowerKey === 'startdate') {
            acc.startDate = parseISO(value)
          }

          if (lowerKey === 'enddate') {
            acc.endDate = parseISO(value)
          }
        }
      }

      return acc
    },
    {}
  )
}

export default parseInputValues
