import mapValues from 'lodash/mapValues'
import mapKeys from 'lodash/mapKeys'
import pickBy from 'lodash/pickBy'
import { format } from 'date-fns'
import type {
  FilterValues,
  FilterPanelOutputValues
} from 'components/FilterPanel/types'

const DATE_FILTER_FORMAT = 'yyyy-MM-dd'

export const parseOutputValues = (
  values: FilterValues
): FilterPanelOutputValues =>
  pickBy(
    {
      startdate: values.startDate
        ? format(values.startDate, DATE_FILTER_FORMAT)
        : null,
      enddate: values.endDate
        ? format(values.endDate, DATE_FILTER_FORMAT)
        : null,
      ...mapValues(
        mapKeys(values.dropdownsValues, (_, key) => `dropdown_${key}`),
        value => value.map(({ id }) => id)
      )
    },
    value => value !== null && value.length > 0
  )

export default parseOutputValues
