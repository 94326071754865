import { Box, Button, Modal, Typography } from '@mui/material'

import { QuestionPicker } from 'components/QuestionPicker'
import type { QuestionData } from 'components/QuestionPicker/types'
import { DataProvider } from 'providers/data'

export interface FilterPanelQuestionSelectModalProps {
  isModalOpen: boolean
  handleCloseModal: () => void
  questionsUrl: string
  questionsToOmit?: string[]
  handleQuestionTwoSelect: (questionInfo?: QuestionData) => void
}

export const FilterPanelQuestionSelectModal = ({
  isModalOpen,
  handleCloseModal,
  questionsUrl,
  questionsToOmit,
  handleQuestionTwoSelect
}: FilterPanelQuestionSelectModalProps) => (
  <Modal
    open={isModalOpen}
    onClose={handleCloseModal}
    aria-labelledby='modal-title'
    aria-describedby='modal-description'
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        minWidth: 300,
        maxWidth: 700,
        bgcolor: 'background.paper',
        p: 4
      }}
    >
      <Typography id='modal-title' variant='h6' component='h2'>
        Pick a second question to compare:
      </Typography>
      <Box sx={{ my: 2, maxHeight: '70vh', overflowY: 'auto' }}>
        <DataProvider src={questionsUrl}>
          <QuestionPicker
            questionsToOmit={questionsToOmit}
            handleQuestionPick={(questionInfo: QuestionData) => {
              handleQuestionTwoSelect(questionInfo)
              handleCloseModal()
            }}
          />
        </DataProvider>
      </Box>
      <Button
        variant='contained'
        color='primary'
        onClick={handleCloseModal}
        sx={{ mt: 2, ml: 'auto', float: 'right' }}
      >
        Close
      </Button>
    </Box>
  </Modal>
)

export default FilterPanelQuestionSelectModal
