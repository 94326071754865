import { useEffect, useState, useMemo } from 'react'
import classNames from 'classnames'

import { Container, Box, Button, Stack } from '@mui/material'
import DateRangePicker from 'components/DateRangePicker'
import type {
  FilterPanelDropdownsValues,
  FilterPanelDropdownType
} from 'components/FilterPanel/types'
import type { DataProviderContext } from 'providers/data'
import type { SelectData } from 'components/PureSelect/types'
import { parseInputValues, parseOutputValues } from './utils'
import Loader from 'components/Loader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import type {
  FilterPanelDropdownsIdValues,
  FilterPanelOutputValues
} from './types'
import type { QuestionData } from 'components/QuestionPicker/types'
import {
  FilterPanelDropdowns,
  FilterPanelQuestionSelectModal
} from 'components/FilterPanel'

import './FilterPanel.css'

const DEFAULT_DROPDOWN_TYPE: FilterPanelDropdownType = 'multi_select'
const PANEL_WIDTH = 285

export interface FilterPanelProps {
  className?: string
  style?: Object
  panelWidth?: `${number}px` | number

  applyFilters: (
    filterValues: FilterPanelOutputValues,
    defaultFilterValues: FilterPanelDropdownsIdValues
  ) => void
  dropdownType?: FilterPanelDropdownType
  initialValues?: Record<string, string | string[]>
  questionOneId?: string
  questionTwoId?: string
  questionsUrl: string
  handleQuestionTwoSelect: (questionInfo?: QuestionData) => void
}

export const FilterPanel = ({
  className,
  style,
  panelWidth = PANEL_WIDTH,
  applyFilters,
  dropdownType = DEFAULT_DROPDOWN_TYPE,
  initialValues,
  handleQuestionTwoSelect,
  questionOneId,
  questionTwoId,
  questionsUrl,

  data: filtersData
}: FilterPanelProps & DataProviderContext<SelectData>): JSX.Element => {
  const [dropdownsValues, setDropdownsValues] =
    useState<FilterPanelDropdownsValues>({})
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const [initialQueryParamsApplied, setInitialQueryParamsApplied] =
    useState<boolean>(false)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const validFilters = useMemo(
    () =>
      (filtersData ?? [])
        .filter(({ is_enabled }) => is_enabled)
        .filter(
          ({ filter_type }) =>
            dropdownType === 'all' || filter_type === dropdownType
        ),
    [filtersData, dropdownType]
  )

  const defaultFilterValues = useMemo(
    () =>
      validFilters.reduce((acc, aFilter) => {
        return { ...acc, [aFilter.id]: aFilter.options.map(({ id }) => id) }
      }, {}),
    [validFilters]
  )

  useEffect(() => {
    if (!filtersData || initialQueryParamsApplied) {
      return
    }

    const parsedValues = parseInputValues(initialValues, validFilters)

    if (parsedValues.startDate) {
      setStartDate(parsedValues.startDate)
    }
    if (parsedValues.endDate) {
      setEndDate(parsedValues.endDate)
    }
    if (parsedValues.dropdownsValues) {
      setDropdownsValues(parsedValues.dropdownsValues)
    }

    applyFilters(
      parseOutputValues({
        startDate: parsedValues.startDate ?? null,
        endDate: parsedValues.endDate ?? null,
        dropdownsValues: parsedValues.dropdownsValues ?? {}
      }),
      defaultFilterValues
    )

    setInitialQueryParamsApplied(true)
  }, [
    applyFilters,
    filtersData,
    validFilters,
    defaultFilterValues,
    initialValues,
    initialQueryParamsApplied
  ])

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Container
      disableGutters
      className={classNames('gt-filter-panel', className)}
      style={{
        width: 'fit-content',
        ...style
      }}
    >
      {!filtersData ? (
        <Loader style={{ width: panelWidth }} />
      ) : (
        <Box
          sx={{
            width: panelWidth,
            overflowY: 'auto',
            height: 'fit-content',
            bgcolor: 'white',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '1rem'
          }}
        >
          <form
            onSubmit={e => {
              e.preventDefault()

              const parsedValues = parseOutputValues({
                startDate,
                endDate,
                dropdownsValues
              })
              applyFilters(parsedValues, defaultFilterValues)
            }}
          >
            <Stack
              spacing={2}
              sx={{
                width: '100%'
              }}
            >
              <Box sx={{ m: 2 }}>
                {questionTwoId ? (
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => handleQuestionTwoSelect()}
                  >
                    <ArrowBackIcon /> Go back
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={handleOpenModal}
                  >
                    + Compare against another question
                  </Button>
                )}
              </Box>
              <Box
                display='flex'
                justifyContent='space-between'
                sx={{ width: '100%' }}
                gap={2}
              >
                <Button fullWidth variant='outlined' type='submit'>
                  Apply Filters
                </Button>
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={() => {
                    setStartDate(null)
                    setEndDate(null)
                    setDropdownsValues({})
                  }}
                >
                  Reset All
                </Button>
              </Box>
              <Box display='flex' justifyContent='center' sx={{ m: 2 }}>
                <DateRangePicker
                  startDate={startDate}
                  handleStartDateChange={(newDate: Date | null) => {
                    setStartDate(newDate)
                  }}
                  endDate={endDate}
                  handleEndDateChange={(newDate: Date | null) => {
                    setEndDate(newDate)
                  }}
                />
              </Box>
              <Box sx={{ m: 2 }}>
                <FilterPanelDropdowns
                  dropdownsValues={dropdownsValues}
                  filters={validFilters}
                  setDropdownsValues={setDropdownsValues}
                />
              </Box>
            </Stack>
          </form>
        </Box>
      )}
      <FilterPanelQuestionSelectModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        questionsUrl={questionsUrl}
        questionsToOmit={questionOneId ? [questionOneId] : undefined}
        handleQuestionTwoSelect={handleQuestionTwoSelect}
      />
    </Container>
  )
}

export default FilterPanel
