import { withData } from 'hocs/withData'
import { FilterPanel as CFilterPanel, FilterPanelProps } from './FilterPanel'
import { FilterPanelDropdowns } from './FilterPanelDropdowns'
import { FilterPanelQuestionSelectModal } from './FilterPanelQuestionSelectModal'

export const FilterPanel = withData(CFilterPanel, {
  showError: true
})

export type { FilterPanelProps }

export { FilterPanelDropdowns, FilterPanelQuestionSelectModal }
export default FilterPanel
