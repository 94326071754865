import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'
import {
  QuestionData,
  QuestionPickerDataParsed
} from 'components/QuestionPicker/types'

export function defaultQuestionPickerDataParser (
  rawData: QuestionData[],
  questionsToOmit: string[] = []
): QuestionPickerDataParsed {
  return sortBy(
    Object.entries(groupBy(rawData, 'category'))
      .map(([category, categoryInfo]) => {
        return {
          category,
          questions: sortBy(
            categoryInfo
              .filter(
                ({ variable_key }) => !questionsToOmit.includes(variable_key)
              )
              .map(({ question_text, variable_key }) => ({
                questionText: question_text,
                variableKey: variable_key
              })),
            ['questionText']
          )
        }
      })
      .filter(({ questions }) => questions.length > 0),
    ['category']
  )
}
