import classNames from 'classnames'
import Box from '@mui/material/Box'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import './DateRangePicker.css'

export interface DateRangePickerProps {
  className?: string
  style?: object

  startDate: Date | null
  endDate: Date | null

  handleStartDateChange: (date: Date | null) => void
  handleEndDateChange: (date: Date | null) => void
}

export const DateRangePicker = ({
  className,
  style,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange
}: DateRangePickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Box
      className={classNames('gt-date-range-picker', className)}
      style={style}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5em',
        width: '100%'
      }}
    >
      <DatePicker
        label='Start'
        value={startDate}
        format='yyyy/MM/dd'
        onChange={handleStartDateChange}
      />
      <DatePicker
        label='End'
        value={endDate}
        format='yyyy/MM/dd'
        onChange={handleEndDateChange}
      />
    </Box>
  </LocalizationProvider>
)

export default DateRangePicker
