import { withData } from 'hocs/withData'
import {
  QuestionPicker as CQuestionPicker,
  QuestionPickerProps
} from './QuestionPicker'

export const QuestionPicker = withData(CQuestionPicker, {
  showLoader: true,
  showError: true
})

export type { QuestionPickerProps }

export default QuestionPicker
